import Model from "../Model";

class Notification extends Model {
    buildUrl ({ param }) {
        return ['notifications', param];
    }

    async byId(form) {
        return await this.request({
            method: 'POST',
            url: 'notifications',
            data: form
        });
    }

    async store(form) {
        return await this.request({
            method: 'POST',
            url: 'notifications/store',
            data: form
        });
    }

    async update(form) {
        return await this.request({
            method: 'POST',
            url: 'notifications/update',
            data: form
        });
    }

    async destroy(form) {
        return await this.request({
            method: 'POST',
            url: 'notifications/destroy',
            data: form
        });
    }

    async send(form) {
        return await this.request({
            method: 'POST',
            url: 'notifications/send',
            data: form
        });
    }
}

export default new Notification;